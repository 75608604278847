<template>

    <div class="select">
        <div class="table-container">
            <div class="button_container" v-if="hasManage">
                <el-button size="small" type="primary" class="add_button" @click="addManage">添加管理员</el-button>
            </div>
            <el-table border :data="this.$store.state.user.user.list">
                <el-table-column type="index" :index="indexCompute" label="序号" align="center"
                                 width="60px"></el-table-column>
                <el-table-column prop="name" label="管理员姓名" align="center" min-width="100px">
                </el-table-column>
                <el-table-column prop="identity" label="身份" align="center" min-width="100px">
                </el-table-column>
                <el-table-column prop="account" label="账号" align="center" min-width="100px">
                </el-table-column>
                <el-table-column prop="permissions" label="权限" align="center" min-width="400px">
                    <template slot-scope="scope">
                        {{scope.row.permissions.map(item=>permissions[item]).join("、")}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150px" v-if="hasManage">
                    <template slot-scope="scope">
                        <el-link type="primary" icon="el-icon-s-operation" v-if="!scope.row.superAdmin"
                                 @click="updateHandler(scope.row)">修改
                        </el-link>
                        <el-link type="primary" icon="el-icon-delete" style="margin-left: 10px"
                                 @click="deleteHandler(scope.row)" v-if="!scope.row.superAdmin">删除
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-container">
            <el-pagination class="pagination afterClearfix"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="pagination.page+1"
                           :page-sizes="[10, 20, 30, 50]"
                           :page-size="pagination.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="this.$store.state.user.user.total"
                           style="float: right">
            </el-pagination>
        </div>
        <router-view/>
    </div>
</template>

<script>
    import {setData, checkPermission} from "@/utils/cookies";

    export default {
        name: 'List',
        data() {
            return {
                pagination: {
                    page: 0,
                    size: 10,
                },
                hasManage: checkPermission("putAdmin"),
                permissions: {
                    "putExcel": "上传教材Excel",
                    "putBook": "修改教材内容",
                    "deleteBook": "删除教材内容",
                    "addBook": "添加教材内容",
                    "getClass": "查看首页班级统计",
                    "getTeacher": "查看首页教师活跃度",
                    "getLanguage": "查看首页语文用户练习统计",
                    "getWrong": "查看首页常见错词统计",
                    "putAdmin": "添加管理员",
                    "putAudit": "审核管理",
                }
            }
        },
        mounted() {
            this.getUserList();
        },
        methods: {
            indexCompute(index) {
                return index + 1 + this.pagination.page * this.pagination.size;
            },


            // viewHandler(row) {
            //     setData('user', row);
            //     // this.$router.push('/user/small/see');
            // },
            handleSizeChange(size) {
                this.pagination.size = size;
                this.getUserList();
            },
            handleCurrentChange(page) {
                this.pagination.page = page - 1;
                this.getUserList();
            },
            getUserList() {
                this.$store.dispatch("user/userList", {...this.pagination});
            },

            //删除管理员
            deleteHandler(row) {
                console.log(row);
                this.$store.dispatch("user/deleteUser", {'id': row.id}).then((data) => {
                    if (this.$store.state.user.user.list.length === 1) {
                        this.pagination.page--
                    }
                    this.getUserList();
                });
            },
            // 添加管理员
            addManage() {
                this.$router.push('/manage/manage/add');
            },
            //修改管理员
            updateHandler(row) {
                setData('permissionList', row)
                this.$router.push('/manage/manage/update');
            }

        }
    }
</script>

<style scoped lang="scss">
    .button_container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    .edit-container {

        text-align: center;

        .title {
            margin-bottom: 50px;
        }

        .row-container {
            padding: 8px 0;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;

            /*上传图片框修饰*/
            .upload_container {
                width: 300px;
                display: inline-block;
                text-align: start;
            }

            .avatar-uploader, .el-upload {
                border: 1px dashed #d9d9d9;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                display: inline-block;
                height: 130px;
                width: 130px;
            }

            .el-upload {
                border: 1px dashed #d9d9d9;
            }

            .avatar-uploader, .el-upload:hover {

            }

            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 130px;
                height: 130px;
                line-height: 130px;
                text-align: center;
            }

            .avatar {
                width: 130px;
                height: 130px;
                display: block;
            }

            .url_image {
                height: 100%;
                margin-right: 10px;
                display: flex;
                justify-content: center;
            }

            .right-align {
                display: inline-block;
                text-align: right;
                width: 110px;
                height: 30px;
                line-height: 30px;
            }

            .red {
                color: red;
                padding: 5px;
            }

            .text {
                padding-left: 25px;
            }

            .input {
                display: inline-block;
                width: 300px;
                padding-left: 10px;
            }

            .textarea_container {
                height: 50px;
            }

            .textarea_text {
                height: 50px;
                line-height: 50px;
            }

            .switch_class {
                text-align: left;
            }

            .state_class {
                display: inline-block;
                height: 20px;
                line-height: 20px;
                vertical-align: bottom;
                margin-left: 8px;
            }
        }

        .password-tip {
            margin-left: 185px;
            padding: 5px;
            width: 315px;
            text-align: left;
            color: #aaaaaa;
            display: block;
        }

        .action-container {
            margin-top: 30px;
            margin-left: 130px;
        }

        .save {
            padding: 12px 28px;
        }

        .cancel {
            margin-left: 20px;
            padding: 12px 28px;
        }

    }


</style>
